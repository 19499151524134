import {GET_BASE_URL} from "../constants";

export const UploadImage = (file: File) => {

    const formData = new FormData();
    formData.append('upload_img', file);

    return fetch(GET_BASE_URL + '/api/v1/uploadImage', {
        method: 'POST',
        body: formData,
    }).then((response) => response.json())
}