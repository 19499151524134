import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import heroImage from '../assets/img/mainstreet-sunglasses.png';
import secondaryImage from '../assets/img/mw-logo.png';
import familyImage from '../assets/img/familystarwars.jpg';

const Home = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>Disneyland & Disney World | MouseWait</title>
        <meta
          name="description"
          content="Say goodbye to the hassle of planning, and hello to affordable vacations with your loved ones. Unlock a lifetime of priceless memories with LoungeLand Vacation Club."
        />
      </Helmet>

      <div
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 'calc(60vh - 64px)', // Adjusted height for mobile
          width: '100%',
        }}
      ></div>

      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#0D002B',
          color: '#FFBEBD',
          fontWeight: 'bold',
        }}
      >
        <h1>Say goodbye to the hassle of planning, and hello to affordable vacations with your loved ones 🏰</h1>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Changed to column for mobile
          alignItems: 'center',
          padding: '0 20px',
          backgroundColor: '#0D002B',
          color: 'white',
        }}
      >
        <div style={{ maxWidth: '600px', textAlign: 'center' }}>
          {/* <h4>MouseWait for Disneyland and Disney World</h4>
          <p>is your ticket to an unforgettable Disney Parks experience.</p>
          <br /> */}
          <p>
            Discover the magic of our community in{' '}
            <a
              href="https://mousewait.com/disneyland/lounge"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#E8CA49' }}
            >
              The Lounge
            </a>
          </p>
          <br />
          <p>
            Join our free weekly{' '}
            <a
              href="https://mousewait.net/#email"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#E8CA49' }}
            >
              Disney Parks email newsletter here
            </a>
          </p>
          <br />
          <p>
            Get{' '}
            <a
              href="https://lounge.land/all-access"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#E8CA49' }}
            >
              MouseWait ALL-ACCESS here
            </a>
            </p>
          <br />
          <p>
          
            <br />
  Install Mousewait for iOS:
  <br />
  <a
    href="https://apps.apple.com/us/app/mousewait-for-disneyland/id331771613"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: '#E8CA49' }}
  >
    MouseWait for Disneyland
  </a>
  <br />
  <a
    href="https://apps.apple.com/us/app/mousewait-for-disney-world/id437292247"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: '#E8CA49' }}
  >
    MouseWait for Disney World
  </a>
</p>
          
          <br />
          <img src={familyImage} alt="MouseWait for Disneyland and Disney World" style={{ maxWidth: '100%' }} />
        </div>
        <div style={{ maxWidth: '300px', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <img
            src={secondaryImage}
            alt="Family vacation"
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '400px' }}
          />
        </div>
      </div>

      {/* Footer remains the same */}
      <footer
        style={{
          backgroundColor: '#0D002B',
          color: 'white',
          padding: '20px 0',
          textAlign: 'center',
        }}
      >
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 10px' }}>
          <div style={{ marginBottom: '10px' }}>
            <Link
              to="disneyland/lounge"
              style={{ color: 'white', textDecoration: 'none', margin: '0 10px' }}
            >
              Disneyland Real-Time Lounge
            </Link>{' '}
            |{' '}
            <Link
              to="disneyworld/lounge"
              style={{ color: 'white', textDecoration: 'none', margin: '0 10px' }}
            >
              Disney World Lounge
            </Link>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <a
              href="https://www.facebook.com/MouseWait/"
              style={{ color: 'white', textDecoration: 'none', margin: '0 10px' }}
            >
              Facebook
            </a>{' '}
            |{' '}
            <a
              href="https://www.instagram.com/mousewait/"
              style={{ color: 'white', textDecoration: 'none', margin: '0 10px' }}
            >
              Instagram
            </a>{' '}
            |{' '}
            <a
              href="https://twitter.com/mousewait"
              style={{ color: 'white', textDecoration: 'none', margin: '0 10px' }}
            >
              Twitter
            </a>
          </div>
          <div>© {currentYear} MouseWait for Disneyland & Disney World. All rights reserved.</div>
        </div>
      </footer>

      <style>
        {`
          @media (max-width: 767px) {
            div[style*="backgroundColor"] {
              background-color: #0D002B !important;
            }

            div[style*="color:#FFBEBD"] {
              color: #FFBEBD !important;
            }

            div[style*="flexDirection: row"] {
              flex-direction: column !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default Home;