import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { Link } from "react-router-dom";
import {
  fetchMyCollection,
  fetchUser,
  giftCredits,
} from "../redux/lounges/slice";
import { selectLounges } from "../redux/lounges/selectors";
import { LoungeHeader } from "../components/LoungeHeader";
import { MobileLoungeHeader } from "../components/MobileLoungeHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { GET_BASE_URL_IMAGE, dTime } from "../constants";
const dummyUsers: any = [
  {
    userName: "TestUser",
    email: "test",
    userImage:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
  },
  {
    userName: "TestUser",
    email: "test",
    userImage:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
  },
  {
    userName: "TestUser",
    email: "test",
    userImage:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
  },
  {
    userName: "TestUser",
    email: "test",
    userImage:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
  },
];

const GiftCredits = () => {
  const dispatch = useAppDispatch();
  const { myCollectionItem, userItem } = useSelector(selectLounges);
  const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
  const [searchText, setSearchText] = useState("");
  const [credits, setCredits] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  let sortType: any = null;
  const handleSearch = () => {
    if (searchText) {
      dispatch(fetchUser({ key: searchText })).then(() => setIsSearched(true));
    }
  };
  const handleSendCredit = (id: number | null) => {
    const user_id = localStorage.getItem("user_id");
    if (id && credits) {
      if (id.toString() === user_id) {
        toast.error("Please select another user");
        setIsModalOpen(null);
        return;
      }
      dispatch(giftCredits({ id, credits, setLoading: setIsModalOpen }));
    } else {
      toast.error("Please add credits to send");
    }
  };

  useEffect(() => {
      // Check if the device is iOS
      const checkIsIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      setIsIOS(checkIsIOS());
    dispatch(fetchMyCollection());
  }, []);

  return (
    <>
      <div className="mid-main">
        <div className="container">
          <div className="mid-sec mwstore-page-bg">
            <LoungeHeader />
            <MobileLoungeHeader />
            <div className="top-text-m text-center p-2 text-head text-center">
              <div className="top-mw text-center">
                <h6>Top MouseWaiters of the Day</h6>
                <div className="top-sp d-flex justify-content-center">
                  <p>
                    <span>1. grumpypapa 56.38 #5 (DL)</span>
                    <span>2. grapesoda 52.67 #5 (DL)</span>
                  </p>
                </div>
              </div>
              <div className="text-head-m text-center">
                <ul className="p-0 m-0">
                  <li>
                    <a href="">Best of the Day</a>
                  </li>
                  <li>
                    <a href="">Food Blog</a>
                  </li>
                  <li>
                    <a href="">Tips</a>
                  </li>
                  <li>
                    <a href="">Sticker Store</a>
                  </li>
                </ul>
              </div>
              <div className="Search-bar">
                <div className="input-group  bg-white border rounded-pill p-1">
                  <input
                    type="search "
                    placeholder="Search the Lounge"
                    aria-describedby="button-addon4"
                    className="form-control  border-0"
                  />
                  <div className="input-group-prepend border-0">
                    <button id="button-addon4" type="button" className="btn">
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer autoClose={3000} />
            <div className="mwstore-mid-bg">
              <Tabs style={{ borderTopWidth: 0 }}>
                <TabList>
                  <Tab>
                    {" "}
                    <Link to="/disneyland/mystore"> MW Store </Link>{" "}
                  </Tab>
                  {!isIOS && (
                    <Tab>
                      <Link to='/disneyland/recharge'> Recharge Credits </Link>
                    </Tab>
                  )}
                  <Tab>
                    {" "}
                    <Link to="/disneyland/mycollection">
                      {" "}
                      My Collection{" "}
                    </Link>{" "}
                  </Tab>
                  <Tab>
                    {" "}
                    <Link to="/disneyland/mytrade-request">
                      {" "}
                      My Trade Request{" "}
                    </Link>{" "}
                  </Tab>
                  <Tab>
                    {" "}
                    <Link to="/disneyland/myhistory"> My History </Link>{" "}
                  </Tab>
                  <Tab>
                    {" "}
                    <Link to="/disneyland/giftcredits">Gift Credits </Link>{" "}
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="MW-buy-sec pb-4">
                    <div className="container p-0">
                      <div className="row">
                        <div className="buy-m">
                          <div className="mws-mid-sec mb-2">
                            <h5 className="mid-head">Gift Credits</h5>
                          </div>

                          <div className="d-flex justify-content-between">
                            <h6
                              className="mid-sub"
                              style={{ fontWeight: "600" }}
                            >
                              <span>
                                Credit Balance :{" "}
                                {(myCollectionItem as any).credit_balance}
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div
                      className="input-group  bg-white border rounded-pill p-1"
                      style={{ maxWidth: "400px", margin: "20px auto" }}
                    >
                      <input
                        type="search "
                        placeholder="Search user to send credits"
                        aria-describedby="button-addon4"
                        className="form-control  border-0"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <div className="input-group-prepend border-0">
                        <button
                          id="button-addon4"
                          type="button"
                          className="btn"
                          onClick={handleSearch}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                    {isSearched && userItem.length ? (
                      <table className="table table-borderless">
                        <thead>
                          <tr className="tab-nav">
                            <th
                              style={{
                                width: "15%",
                                fontWeight: "500",
                                fontSize: "18px",
                              }}
                              scope="col"
                            >
                              Image
                            </th>
                            <th
                              style={{
                                width: "20%",
                                fontWeight: "500",
                                fontSize: "18px",
                              }}
                              scope="col"
                            >
                              User Name
                            </th>
                            <th
                              style={{
                                width: "20%",
                                fontWeight: "500",
                                fontSize: "18px",
                              }}
                              scope="col"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody className="tab-body">
                          {userItem?.map((obj: any) => {
                            console.log(
                              GET_BASE_URL_IMAGE +
                                "/disneyland/images/thumbs/" +
                                obj?.image +
                                dTime
                            );
                            return (
                              <tr className="in-sec">
                                <td scope="row">
                                  <div className="tab-img">
                                    <img
                                      src={
                                        GET_BASE_URL_IMAGE +
                                        "/disneyland/images/thumbs/" +
                                        obj?.image +
                                        dTime
                                      }
                                      className="img-fluid"
                                      alt="big-top"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </div>
                                </td>
                                <td style={{ fontWeight: "500" }}>
                                  {obj?.value}
                                </td>
                                <td style={{ fontWeight: "500" }}>
                                  <button
                                    onClick={() => setIsModalOpen(obj?.id)}
                                    className="btn btn-primary"
                                  >
                                    Send Credits
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      isSearched && <h4>User not found</h4>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen !== null}
          onRequestClose={() => setIsModalOpen(null)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="row">
            <div className="box-t-1">
              <h6 style={{ fontSize: "1rm", fontWeight: 400, color: "red" }}>
                Gift Credits
              </h6>
              <div className="close-p" onClick={() => setIsModalOpen(null)}>
                <i className="fa fa-close my-b" />
              </div>
              <div className="boxwidth">
                <div className="box-ttt">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter amount to send"
                    value={credits}
                    onChange={(e) => setCredits(e.target.value)}
                  />

                  <div className="mw-post text-center">
                    <button
                      className="MW-btn"
                      onClick={() => handleSendCredit(isModalOpen)}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default GiftCredits;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "100%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    background: "#F5F5F5",
    transform: "translate(-50%, -50%)",
  },
};
