import { memo } from "react";

import { useState, useMemo } from "react";

import { useAppDispatch } from "../redux/store";
import { addSticker } from "../redux/lounges/slice";
import { GET_BASE_URL_IMAGE } from "../constants/apiEndpoints";
import moment from "moment";
type StickerTabPropsType = {
  tabData: any;
  onClickSticker: any;
};

function Tab(props: any) {
  const dispatch = useAppDispatch();
  const onAddSticker = (data: any, item: any) => {
    props.onClick(
      GET_BASE_URL_IMAGE + "/disneyland/images/products_thumbnail/" + data,
      item
    );

    dispatch<any>(addSticker(data));
  };
  const emojiData = props?.filter
    ? props?.allData
    : [...props?.tab?.getemojidata];
  const sortedCollection = emojiData.length
    ? emojiData.sort((a: any, b: any) => {
        if (props?.sortedBy === "name") {
          return a?.product_name.localeCompare(b?.product_name);
        }
        return moment.utc(b?.createdon).diff(moment.utc(a?.createdon));
      })
    : [];
  return (
    <div className={`tab-content`}>
      <div className="tab-pane fade active show">
        {sortedCollection
          .filter((sticker: any) =>
            sticker?.product_name
              ?.toLowerCase()
              ?.includes(props?.filter?.toLowerCase())
          )
          .map((item: any, index: any) => (
            <img
              key={index}
              onClick={() => onAddSticker(item.product_image, item)}
              src={
                GET_BASE_URL_IMAGE +
                "/disneyland/images/products_thumbnail/" +
                item.product_image
              }
              className="img-fluid"
              alt={item?.product_name}
            />
          ))}
      </div>
    </div>
  );
}

function Navigation(props: any) {
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="post-sec">
        <ul className={`nav nav-pills`}>
          {props.tabs.map((item: any) => (
            <li key={item?.id} className={`nav-item`}>
              <span
                className={`nav-link ${
                  props.activeTabId === item?.id ? "active" : ""
                }`}
                onClick={() => props.onNavClick(item?.id)}
              >
                {item.emoji_category_name}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="Search-bar">
        <div className="input-group  bg-white border rounded-pill p-1">
          <input
            placeholder="Search ..."
            aria-describedby="button-addon4"
            className="form-control  border-0"
            value={props?.filter}
            onChange={(e) => props?.setFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex gap-3 px-1 align-items-center">
        <h6 style={{ fontWeight: "600" }}>Sorted By</h6>
        <div>
          <select
            className="form-select"
            aria-label="Default select example"
            value={props?.sortedBy}
            onChange={(e) => {
              localStorage.setItem("sortedBy", e.target.value);
              props?.setSortedBy(e.target.value);
            }}
          >
            <option value={"name"}>Name</option>
            <option value={"date"}>Date</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export const StickerTabs: React.FC<StickerTabPropsType> = ({
  tabData,
  onClickSticker,
}) => {
  const [activeTabId, setActiveTab] = useState(tabData[0]?.id);
  const [filter, setFilter] = useState("");
  const [sortedBy, setSortedBy] = useState(
    localStorage.getItem("sortedBy") || "name"
  );
  const activeTab = useMemo(
    () => tabData.find((tab: any) => tab?.id === activeTabId),
    [activeTabId, tabData]
  );
  const allData = useMemo(() => {
    let data: any = [];
    tabData.map((item: any) => {
      data = [...data, ...item?.getemojidata];
    });
    return data;
  }, [tabData]);
  return (
    <div className="com-box-main">
      <div className="post-sec">
        <div className={`tabs`}>
          <Navigation
            tabs={tabData}
            onNavClick={setActiveTab}
            activeTabId={activeTabId}
            setSortedBy={setSortedBy}
            sortedBy={sortedBy}
            setFilter={setFilter}
            filter={filter}
          />
          <Tab
            tab={activeTab}
            onClick={onClickSticker}
            sortedBy={sortedBy}
            filter={filter}
            allData={allData}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(StickerTabs);
