import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLounges } from "../redux/lounges/selectors";
type TopImgesPropsType = {
  chatId: number;
  gettagged: [];
};

export const TopTags: React.FC<TopImgesPropsType> = ({ chatId, gettagged }) => {
  return (
    <>
      <div className="card-body">
        <div className="t-link text-center">
          <ul className="m-0 p-0 taglist">
            {gettagged?.map((obj: any) => (
              <li>
                <Link
                  to={`/disneyland/tag/${obj.gettagged[0]?.tags_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}/`}
                >
                  {obj.gettagged[0]?.tags_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export const TopTagsLounge: React.FC<TopImgesPropsType> = ({ chatId, gettagged }) => {
  const [topTags, setTopTags] = useState([]);
  const { items } = useSelector(selectLounges);
  useEffect(() => {
    const post = items?.filter((item) => item?.chat_id === chatId);
    setTopTags(post?.[0]?.tagcomposit);
  }, [items]);

  return (
    <>
      <div className="card-body">
        <div className="t-link text-center">
          <ul className="m-0 p-0 taglist">
            {topTags?.map((obj: any) => (
              <li>
                <Link
                  to={`/disneyland/tag/${obj.gettagged[0]?.tags_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}/`}
                >
                  {obj.gettagged[0]?.tags_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
